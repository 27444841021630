<template>
  <v-stepper v-model="currentStep">

    <v-dialog v-model="dialog" fullscreen hide-overlay>
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text>

            <v-container grid-list-md>
              <v-row
                style="height: 60px;"
              >
                <v-col cols="12">
                  <v-text-field 
                    ref="fullName"
                    v-model="member.fullName" 
                    :label="$t(fullNameLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row
                style="height: 60px;"
              >
                <v-col cols="12">
                  <v-text-field 
                    v-model="member.nationality" 
                    :label="$t(nationalityLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row
                style="height: 60px;"
              >
                <v-col cols="12">
                  <v-text-field 
                    v-model="member.idNumber" 
                    :label="$t(idNumberLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row
                style="height: 80px;"              
              >
                <v-col cols="12">
                  <v-text-field 
                    v-model="member.phoneNumber" 
                    :label="$t(phoneNumberLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row
                style="height: 60px;"              
              >
                <v-col cols="12">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menuBirthDate"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="member.birthDate"
                        :label="$t(birthDateLabel)"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        :rules="[rules.required]"
                        required
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="member.birthDate" no-title @input="menuBirthDate = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row
                style="height: 60px;"              
              >
                <v-col cols="12">
                  <v-text-field 
                    v-model="member.address" 
                    :label="$t(addressLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>   

              <v-row
                style="height: 60px;"              
              >
                <v-col cols="6">
                  <v-text-field 
                    v-model="member.gender" 
                    :label="$t(genderLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field 
                    v-model="member.occupation" 
                    :label="$t(occupationLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>   

              <v-row
                style="height: 80px;"
              >
                <v-col cols="6">
                  <v-text-field 
                    v-model="member.prevPlace" 
                    :label="$t(prevPlaceLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field 
                    v-model="member.nextDest" 
                    :label="$t(nextDestLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>   

              <v-row style="height: 60px;">
                <v-col cols="6">
                  <v-chip
                    class="ma-2"
                    :color="idIconColor"
                    label
                    text-color="white"
                  >
                    <v-icon>badge</v-icon>
                    Passport
                  </v-chip>
                </v-col>
                <v-col cols="6">
                  <v-chip
                    class="ma-2"
                    :color="faceIconColor"
                    label
                    text-color="white"
                  >
                    <v-icon>face_6</v-icon>
                    Portrait
                  </v-chip>
                </v-col>
              </v-row>   
         
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeDialog">Close</v-btn>
            <v-btn color="primary" text @click="saveDialog">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    
    <v-stepper-items
      v-touch="{
        left: () => swipe('Left'),
        right: () => swipe('Right'),
      }"
    >
      <v-stepper-content
        v-for="n in steps"
        :key="`${n}-content`"
        :step="n"
      >
        <v-container fluid>
          <v-row
            align="start"
            no-gutters
            style="height: 20px;"
          >
            <v-col cols="12">
              <v-file-input
                @change="idImagePicked"
                accept="image/*"
                :label="$t(idBtnLabel)"
                v-model="idImage"
                prepend-icon="photo_camera"
                ref="idFileInput"
              ></v-file-input>
            </v-col>
          </v-row>
          
          <v-row
            style="height: 180px;"
          >
            <v-col cols="12">
              <v-img :src="member.idImgSrc" style="height: 160px; width: 160px; cursor: pointer;" @click="triggerIdInput"></v-img>
            </v-col>
          </v-row>

          <v-row
            style="height: 60px;"
          >
            <v-col cols="12">
              <v-file-input
                @change="faceImagePicked"
                accept="image/*"
                :label="$t(faceBtnLabel)"
                v-model="faceImage"
                prepend-icon="photo_camera"
                ref="faceFileInput"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-row
            style="height: 200px;"
          >
            <v-col cols="12">
              <v-img :src="member.faceImgSrc" style="height: 180px; width: 160px; cursor: pointer;" @click="triggerFaceInput"></v-img>
            </v-col>
          </v-row>

          <v-row
            style="height: 40px;"
          >
            <v-col cols="12">
              <v-btn rounded :color="infoBtnColor" @click="openDialog()" class="headline">{{ $t(infoBtnLabel) }}</v-btn>
            </v-col>
          </v-row>
          
        </v-container>

        <v-container fluid>
          <v-row
            style="height: 100px;"
          >
            <v-col cols="12">
              <v-toolbar
                slot="footer"
                color="primary"
                dark
              >
                <v-spacer/>
                <v-btn class="headline" icon @click="prevStep(n)">
                  <v-icon x-large>navigate_before</v-icon>
                </v-btn>
                <span class="headline">Guest {{ currentStep }} of {{ steps }}</span>
                <v-btn class="headline" icon @click="nextStep(n)">
                  <v-icon x-large>navigate_next</v-icon>
                </v-btn>
                <v-spacer/>
              </v-toolbar>
            </v-col>
          </v-row>
        </v-container>

      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { Storage } from 'aws-amplify'
import { mapState, mapGetters, mapActions } from 'vuex'
import { CallDialog } from '@/utils'
import store from '@/store'

export default {
  name: 'CheckIn',
  data () {
    return {
      rules: {
        required: value => !!value || 'Value Required',
      },
      infoBtnLabel: 'checkIn.btn_id_info',
      faceBtnLabel: 'checkIn.btn_face_upload',
      idBtnLabel: 'checkIn.btn_id_upload',
      fullNameLabel: 'member.fullName',
      genderLabel: 'member.gender',
      birthDateLabel: 'member.birthDate',
      nationalityLabel: 'member.nationality',
      idNumberLabel: 'member.idNumber',
      occupationLabel: 'member.occupation',
      addressLabel: 'member.address',
      phoneNumberLabel: 'member.phoneNumber',
      prevPlaceLabel: 'member.prevPlace',
      nextDestLabel: 'member.nextDest',
      dialog: false,
      menuBirthDate: false,
      // faceImageUrl: null,
      faceImage: null,
      idImageUrl: null,
      idImage: null,
    }
  },
  created () {
    this.setCurrentStep(1)
  },
  async beforeRouteEnter (to, from, next) {
    const error = await store.dispatch('checkIn/loadMembers').catch(error => {
      return error
    })

    if (error) {
      CallDialog(error)
    } else {
      next()
    }
  },
  // async beforeRouteUpdate (to, from, next) {
  //   const error = await store.dispatch('checkIn/loadMembers').catch(error => {      
  //     return error
  //   })

  //   if (error) {
  //     CallDialog(error)
  //   } else {
  //     next()
  //   }
  // },
  computed: {
    idIconColor: {
      get() {
        if (this.member.idImgKey) {
          return 'success'
        } else {
          return 'error'
        }
      }
    },
    faceIconColor: {
      get() {
        if (this.member.faceImgKey) {
          return 'success'
        } else {
          return 'error'
        }
      }
    },
    infoBtnColor: {
      get() {
        if (this.memberInfoFilled) {
          return 'success'
        } else {
          return 'error'
        }
      }
    },
    currentStep: {
      get() {
        return this.$store.state.checkIn.currentStep
      },
      set (val) {
        return this.setCurrentStep(val)
      }
    },
    ...mapState({
      members: state => state.checkIn.members,
      member: state => state.checkIn.currentMember,
      reservation: state => state.checkIn.reservation
    }),
    ...mapGetters('checkIn', {
      steps: 'steps',
      memberInfoFilled: 'memberInfoFilled',
    })
  },
  methods: {
    triggerIdInput() {
      this.$refs.idFileInput[0].$refs.input.click();
    },

    triggerFaceInput() {
      this.$refs.faceFileInput[0].$refs.input.click();
    },
    async idImagePicked () {

      const blob = await(new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(this.idImage);
      }))

      console.log('idImagePicked', blob)

      await this.putImage({imgBufferArray: blob, isId: true})

      // const error = await this.saveMember(true).catch(e => {
      //   console.error('saveMember', e)
      //   return e
      // })

      // if (error) {
      //   CallDialog(error)
      //   return
      // } 
    },
    async faceImagePicked () {

      const faceImageUrl = URL.createObjectURL(this.faceImage)

      console.log('this.faceImage', this.faceImage)
      console.log('faceImageUrl', faceImageUrl)

      const imgElement = document.createElement('img')

      imgElement.src = faceImageUrl

      const detectedFaces = await this.detectFaces(imgElement)

      if (!detectedFaces) {
        CallDialog(new Error('Need a clear portrait picture.'))
        return  
      }

      if (detectedFaces.length != 1) {
        CallDialog(new Error('Need a portrait picture of one guest only.'))
        return  
      }

      console.log('faceImageDetected', detectedFaces[0])

      await this.putImage({imgBufferArray: detectedFaces[0], isId: false})

    },
    swipe (direction) {
      if (direction === 'Left') {
        this.goNext()
      } else if (direction === 'Right') {
        this.goPrevious()
      }
    },
    prevStep (n) {
      if (n === 1) {
        this.setCurrentStep(this.steps)

      } else {
        this.setCurrentStep(n - 1)
      }
    },
    nextStep (n) {
      if (n === this.steps) {
        this.setCurrentStep(1)
      } else {
        this.setCurrentStep(n + 1)
      }
    },
    goPrevious () {
      this.prevStep(this.currentStep)
    },
    goNext () {
      this.nextStep(this.currentStep)
    },
    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      console.log('this.$refs', this.$refs)


      const valid = this.$refs.form.validate()

      setTimeout(() => {
        this.dialog = false
      }, 200)
    },
    async saveDialog() {
      console.log('this.reservation', this.reservation)


      const valid = this.$refs.form.validate()

      if (valid) {

        const error = await this.saveMember(false).catch(e => {
          console.error('saveMember', e)
          return e
        })

        if (error) {
          CallDialog(error)
          return
        } 

        this.dialog = false

        if (this.reservation.isIdVerified) {
          this.$router.push({path: '/guide'})
        } else {
          this.$router.push({path: '/'})
        }


      }
    },
    ...mapActions({
      setCurrentStep: 'checkIn/setCurrentStep',
      saveMember: 'checkIn/saveMember',
      putImage: 'checkIn/putImage',
      loadMembers: 'checkIn/loadMembers',
      detectFaces: 'checkIn/detectFaces',
    })
  },
  components: {
  }
}
</script>

<style>

img {
  max-width: 100%;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
}
</style>